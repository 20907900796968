import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageWrap from '../components/page-wrap'
import PostSummary from '../components/post-summary'
import mq from '../utils/media-queries'

const PostsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1.75rem;
  grid-row-gap: 1.75rem;
  margin: 0 0 4rem 0;
  ${mq[1]} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq[2]} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Blog = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SEO title="Blog" />
      <PageWrap>
        <h1>The Blog</h1>
        <p style={{ maxWidth: '740px' }}>
          My collection of posts covering development topics, cooking recipes,
          travel experiences and things like that. These posts are populated from forestry.io.
        </p>
        <PostsWrap>
          {posts.map(({ node }) => {
            return (
              <PostSummary
                key={node.id}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                content={node.excerpt}
                link={node.fields.slug}
              />
            )
          })}
        </PostsWrap>
      </PageWrap>
    </Layout>
  )
}

export default Blog

// get posts
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { draft: { eq: false } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
