import React, { Component } from 'react'
import styled from '@emotion/styled'
import Card from '../components/card'
import { Link } from 'gatsby'

const SummaryWrap = styled.article`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  transition: all ease-in 0.1s;
  :hover {
    transform: translateY(-8px);
  }
`
const Title = styled.h2`
  color: ${props => props.theme.black};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 1rem;
  /* &:hover {
    color: ${props => props.theme.primary};
  } */
  &:hover {
      color: ${props =>
        props.light
          ? props => props.theme.white
          : props => props.theme.primary};
    }
  transition: all ease-in-out 100ms;
`
const Summary = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0.5rem;
  flex: 1;
`
const Continue = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 28px;
  color: ${props => props.theme.primary};
  position: relative;
`

export default class PostSummary extends Component {
  render() {
    return (
      <SummaryWrap>
        <Card>
          <Title>
            <Link to={this.props.link}>{this.props.title}</Link>
          </Title>
          <Summary>{this.props.content}</Summary>
          <Continue>
            <Link to={this.props.link}>Continue Reading</Link>
          </Continue>
        </Card>
      </SummaryWrap>
    )
  }
}
