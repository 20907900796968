import React from 'react'
import styled from '@emotion/styled'

const CardWrap = styled.div`
  padding: 1.875rem;
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: ${props => props.theme.radius};
  box-shadow: ${props => props.theme.shadow};
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`

const Card = ({ children }) => <CardWrap>{children}</CardWrap>

export default Card
